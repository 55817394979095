/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Dress code"}>
        <SiteHeader />

        <Column className="--center pb--80 pt--80" name={"gift-registry"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper className="pt--40">
              
              <Title className="title-box title-box--center fs--102 mt--10" content={"Dress code"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":600}} content={"Om alles mooi in harmonie te krijgen vragen wij een dresscode"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"[[SVTsectionnam3]]-2"}>
          
          <ColumnWrap className="column__flex --center el--2 mt--30 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box" content={"WOMAN"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--06 pt--0">
              
              <Text className="text-box" content={"Bohemian - lichte stoffen - soepel - kleurenpallet<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 mt--30 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box" content={"MEN<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--06 pt--0">
              
              <Text className="text-box" content={"Bohemian - Linnen - kleurenpallet -&nbsp;natuurlijke tinten<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--60 pt--60" name={"5a7kmnbbib2"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/30673/151138f59d1745089b94e28b77d1950a_s=660x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":419}} srcSet={"https://cdn.swbpg.com/t/30673/151138f59d1745089b94e28b77d1950a_s=350x_.JPG 350w, https://cdn.swbpg.com/t/30673/151138f59d1745089b94e28b77d1950a_s=660x_.JPG 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/30673/1531e7c6ab16409caa1afbdf8a26c625_s=350x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":419}} srcSet={"https://cdn.swbpg.com/t/30673/1531e7c6ab16409caa1afbdf8a26c625_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/30673/caf05283ff6d47f8940211fae9a0b864_s=350x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":419}} srcSet={"https://cdn.swbpg.com/t/30673/caf05283ff6d47f8940211fae9a0b864_s=350x_.JPG 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/30673/05ff28d2cfc14942a1a77f3e332d00bf_s=350x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":419}} srcSet={"https://cdn.swbpg.com/t/30673/05ff28d2cfc14942a1a77f3e332d00bf_s=350x_.JPG 350w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}